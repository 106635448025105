module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.3.0","publicApiKey":"YzRjMWI5MjgtZDZmOS00MjFmLTg3NDUtZjZhMGMyOTM5NDhiNjM3Nzc1MTQ4NjE3MTgwMjYw","currency":"eur","exclude":"institute","locales":{"en":{"address_form":{"society":"Society","vat":"VAT number"}},"fr":{"address_form":{"society":"Société","vat":"Numéro de TVA intracommunautaire"}}},"templatesUrl":"/snipcart/index.html"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":1000000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"production":{"hardCacheMediaFiles":true,"allow404Images":false,"allow401Images":false},"html":{"useGatsbyImage":true,"createStaticFiles":true,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"type":{"MediaItem":{"localFile":{"requestConcurrency":5,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"url":"https://contenus.uperform.be/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"custom":[{"name":["DINCond","DIN"],"file":"/fonts.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"hsla(255, 255%, 255%, 1.00)","description":"Uperform rassemble des professionnels du monde de la santé et de la préparation physique. Une équipe constituée d’experts de leur domaine pour soigner, promouvoir la santé chez toutes les populations.","display":"standalone","icon":"src/images/logo-u.svg","lang":"fr","localize":[{"description":"Uperform rassemble des professionnels du monde de la santé et de la préparation physique. Une équipe constituée d’experts de leur domaine pour soigner, promouvoir la santé chez toutes les populations.","lang":"en","name":"UPerfom","short_name":"UPerform","start_url":"/en"}],"name":"UPerform","short_name":"UPerform","start_url":"/","theme_color":"hsla(190, 63%, 70%, 1.00)","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bb9b010ea52085dedd52317299ed62d7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
