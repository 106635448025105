import { oneLine, stripIndent } from 'common-tags';
import { Script } from 'gatsby';
import React from 'react';

const DATA_LAYER_NAME = 'dataLayer';
const includeInDevelopment = true;

const shouldRun = process.env.NODE_ENV === 'production' || includeInDevelopment;
const generateGTM = ({
  id,
  dataLayerName,
}: {
  id: string;
  dataLayerName: string;
}) => stripIndent`
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','${dataLayerName}', '${id}');`;

export const loadGtm = () => {
  const id = 'GTM-TH29TPS';
  if (id.length === 0) return null;
  const dataLayerName = DATA_LAYER_NAME;

  if (!shouldRun) return null;

  return (
    <Script
      dangerouslySetInnerHTML={{
        __html: oneLine`
            function initGtm() {
              if (window.gtmDidInit) {
                return false;
              }
              window.gtmDidInit = true;
              ${generateGTM({ dataLayerName, id })}
            }

            function initGTMOnEvent(event) {
              initGtm();
              event.currentTarget.removeEventListener(event.type, initGTMOnEvent);
            }

            document.addEventListener('DOMContentLoaded', () => {
              setTimeout(initGtm, 5000);
            });

            document.addEventListener('scroll', (event) => {
              initGTMOnEvent(event);
            }, { passive: true});
            document.addEventListener('mousemove', (event) => {
              initGTMOnEvent(event);
            });
            document.addEventListener('touchstart', (event) => {
              initGTMOnEvent(event);
            }, { passive: true});`,
      }}
    />
  );
};

declare let window: CustomWindow;

export const gtmRouteUpdate = (): void => {
  if (!shouldRun) return null;
  // wrap inside a timeout to ensure the title has properly been changed
  setTimeout(() => {
    const eventName = 'gatsby-route-change';
    const data = DATA_LAYER_NAME ? window[DATA_LAYER_NAME] : window.dataLayer;
    data?.push({ event: eventName });
  }, 50);
};
