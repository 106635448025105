exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-obfuscated-tsx": () => import("./../../../src/pages/obfuscated.tsx" /* webpackChunkName: "component---src-pages-obfuscated-tsx" */),
  "component---src-templates-single-actualite-tsx": () => import("./../../../src/templates/single/Actualite.tsx" /* webpackChunkName: "component---src-templates-single-actualite-tsx" */),
  "component---src-templates-single-archive-staff-tsx": () => import("./../../../src/templates/single/ArchiveStaff.tsx" /* webpackChunkName: "component---src-templates-single-archive-staff-tsx" */),
  "component---src-templates-single-centre-tsx": () => import("./../../../src/templates/single/Centre.tsx" /* webpackChunkName: "component---src-templates-single-centre-tsx" */),
  "component---src-templates-single-formation-tsx": () => import("./../../../src/templates/single/Formation.tsx" /* webpackChunkName: "component---src-templates-single-formation-tsx" */),
  "component---src-templates-single-page-page-actualites-tsx": () => import("./../../../src/templates/single/Page-Page Actualites.tsx" /* webpackChunkName: "component---src-templates-single-page-page-actualites-tsx" */),
  "component---src-templates-single-page-page-blog-tsx": () => import("./../../../src/templates/single/Page-Page Blog.tsx" /* webpackChunkName: "component---src-templates-single-page-page-blog-tsx" */),
  "component---src-templates-single-page-page-builder-tsx": () => import("./../../../src/templates/single/Page-Page Builder.tsx" /* webpackChunkName: "component---src-templates-single-page-page-builder-tsx" */),
  "component---src-templates-single-page-page-contact-institute-tsx": () => import("./../../../src/templates/single/Page-Page Contact Institute.tsx" /* webpackChunkName: "component---src-templates-single-page-page-contact-institute-tsx" */),
  "component---src-templates-single-page-page-contact-tsx": () => import("./../../../src/templates/single/Page-Page Contact.tsx" /* webpackChunkName: "component---src-templates-single-page-page-contact-tsx" */),
  "component---src-templates-single-page-page-daccueil-tsx": () => import("./../../../src/templates/single/Page-Page D'accueil.tsx" /* webpackChunkName: "component---src-templates-single-page-page-daccueil-tsx" */),
  "component---src-templates-single-page-page-evenement-tsx": () => import("./../../../src/templates/single/Page-Page Evenement.tsx" /* webpackChunkName: "component---src-templates-single-page-page-evenement-tsx" */),
  "component---src-templates-single-page-page-institute-tsx": () => import("./../../../src/templates/single/Page-Page Institute.tsx" /* webpackChunkName: "component---src-templates-single-page-page-institute-tsx" */),
  "component---src-templates-single-page-page-kinequick-tsx": () => import("./../../../src/templates/single/Page-Page Kinequick.tsx" /* webpackChunkName: "component---src-templates-single-page-page-kinequick-tsx" */),
  "component---src-templates-single-page-page-pathologies-tsx": () => import("./../../../src/templates/single/Page-Page Pathologies.tsx" /* webpackChunkName: "component---src-templates-single-page-page-pathologies-tsx" */),
  "component---src-templates-single-page-page-physiotec-tsx": () => import("./../../../src/templates/single/Page-Page Physiotec.tsx" /* webpackChunkName: "component---src-templates-single-page-page-physiotec-tsx" */),
  "component---src-templates-single-page-page-recherche-de-centre-tsx": () => import("./../../../src/templates/single/Page-Page Recherche De Centre.tsx" /* webpackChunkName: "component---src-templates-single-page-page-recherche-de-centre-tsx" */),
  "component---src-templates-single-page-page-services-tsx": () => import("./../../../src/templates/single/Page-Page Services.tsx" /* webpackChunkName: "component---src-templates-single-page-page-services-tsx" */),
  "component---src-templates-single-page-page-thematiques-tsx": () => import("./../../../src/templates/single/Page-Page Thematiques.tsx" /* webpackChunkName: "component---src-templates-single-page-page-thematiques-tsx" */),
  "component---src-templates-single-pathologie-tsx": () => import("./../../../src/templates/single/Pathologie.tsx" /* webpackChunkName: "component---src-templates-single-pathologie-tsx" */),
  "component---src-templates-single-post-tsx": () => import("./../../../src/templates/single/Post.tsx" /* webpackChunkName: "component---src-templates-single-post-tsx" */),
  "component---src-templates-single-service-tsx": () => import("./../../../src/templates/single/Service.tsx" /* webpackChunkName: "component---src-templates-single-service-tsx" */),
  "component---src-templates-single-single-staff-tsx": () => import("./../../../src/templates/single/SingleStaff.tsx" /* webpackChunkName: "component---src-templates-single-single-staff-tsx" */),
  "component---src-templates-single-staff-tsx": () => import("./../../../src/templates/single/Staff.tsx" /* webpackChunkName: "component---src-templates-single-staff-tsx" */),
  "component---src-templates-single-thematique-tsx": () => import("./../../../src/templates/single/Thematique.tsx" /* webpackChunkName: "component---src-templates-single-thematique-tsx" */)
}

