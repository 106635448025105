import './static/fonts.css';
import 'focus-visible/dist/focus-visible';
import './src/components/Layout/global.css';

import { GatsbyBrowser } from 'gatsby';
import React from 'react';

import { gtmRouteUpdate, loadGtm } from './create/loadGtm';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!('IntersectionObserver' in window)) {
    import('intersection-observer');
  }
};

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  gtmRouteUpdate();
};

export const onServiceWorkerUpdateReady: GatsbyBrowser['onServiceWorkerUpdateReady'] =
  () => window.location.reload();

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({element}) => {
  const GtmScript = loadGtm();
  return (
    <>
      {element}
      {GtmScript}
    </>
  );
};
